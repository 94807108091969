// extracted by mini-css-extract-plugin
export var bar = "style-module--bar--6d3fa";
export var close = "style-module--close--90d8a";
export var footer = "style-module--footer--59cd1";
export var handle = "style-module--handle--c52e4";
export var item = "style-module--item--9ef0d";
export var label = "style-module--label--4a771";
export var list = "style-module--list--f61b3";
export var loadingContainer = "style-module--loadingContainer--2cc6d";
export var name = "style-module--name--b796a";
export var next = "style-module--next--48ffe";
export var option = "style-module--option--3f635";
export var options = "style-module--options--efc45";
export var playContainer = "style-module--playContainer--20b85";
export var playPause = "style-module--playPause--f8e73";
export var player = "style-module--player--72959";
export var progress = "style-module--progress--e6c5c";
export var scroll = "style-module--scroll--36da8";
export var slider = "style-module--slider--b50ea";
export var speed = "style-module--speed--57462";
export var status = "style-module--status--ee945";
export var text = "style-module--text--1d5bf";
export var time = "style-module--time--570ce";
export var title = "style-module--title--4a20b";
export var ui = "style-module--ui--38b34";
export var value = "style-module--value--b47ad";
export var volume = "style-module--volume--1c9fc";