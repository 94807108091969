import { useEffect, useState } from 'react'
import axios from 'axios'

import { Article, useArticle } from './Article'
import { Locale, useTranslation } from './Translation'
import { isLocal } from './Util'
import { sendError } from './Sentry'
import { User } from './User'

// Gateway API settings
const GATEWAY_ID = '' + process.env.GATSBY_GATEWAY_ENDPOINT
const GATEWAY_ID_ENGLISH = '' + process.env.GATSBY_GATEWAY_ENDPOINT_ENGLISH

enum EventType {
  view = 'view',
}
type Event = {
  itemId: string
  recommendationId?: string
}
type PostEventsParameters = {
  event: Event
  userId: string
  sessionId: string
  eventType: EventType
  sentAt: number
}
export type RecommendationsResponse = {
  articleIds: string[]
  recommendationId: string
}
export type Recommendations = {
  articles: Article[]
  recommendationId: string
}

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}

export const sendPersonalizeEvent = async (
  event: Event,
  locale: Locale,
  user: User,
) => {
  if (
    isLocal ||
    user === undefined ||
    user.userId === undefined ||
    user.sessionId === undefined
  ) {
    return
  }

  const payload: PostEventsParameters = {
    event: event,
    userId: user.userId,
    sessionId: user.sessionId,
    eventType: EventType.view,
    sentAt: Date.now(),
  }

  return axios
    .post(getEndpoints(locale).events, payload, { headers })
    .catch(error => {
      console.error(JSON.stringify(error))
      sendError(error)
    })
}

export const useRecommendations = (
  article: Article,
  numberOfArticles: number,
): Recommendations => {
  useState<RecommendationsResponse>()
  const [loading, setLoading] = useState(true)
  const [recommendations, setRecommendations] = useState<Recommendations>({
    articles: [],
    recommendationId: '',
  })
  const { getArticles } = useArticle()
  const { locale } = useTranslation()

  useEffect(() => {
    if (!loading) {
      return
    }

    if (isLocal) {
      setLoading(false)
      return
    }

    getRecommendations(article.blogId, numberOfArticles).then(data => {
      if (data === undefined) {
        return
      }

      const recommendationsArticles = getArticles(data.articleIds)
      setRecommendations({
        articles: recommendationsArticles,
        recommendationId: data.recommendationId,
      })
      setLoading(false)
    })
  }, [article, numberOfArticles, getArticles, locale])

  return recommendations
}

const getEndpoints = (locale: Locale) => {
  const gatewayUrl = `https://${
    locale === 'en' ? GATEWAY_ID_ENGLISH : GATEWAY_ID
  }.execute-api.ap-northeast-1.amazonaws.com/v1`

  return {
    events: gatewayUrl + '/events',
    recommendations: gatewayUrl + '/recommendations',
  }
}

export const getRecommendations = async (
  articleId: string,
  numberOfResults?: number,
): Promise<RecommendationsResponse | undefined> => {
  const requestPath =
    numberOfResults !== undefined
      ? `/${articleId}?num_results=${numberOfResults}`
      : `/${articleId}`
  return (
    axios
      // 英語は結果が気になるので、日本語のデータを優先する
      .get(getEndpoints('ja').recommendations + requestPath, {
        headers,
      })
      .then(response => {
        return response.data as RecommendationsResponse
      })
      .catch(error => {
        console.error(JSON.stringify(error))
        sendError(error)
        return undefined
      })
  )
}
