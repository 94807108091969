import { useMemo } from 'react'

import { useAllArticles as useJapaneseAllArticles } from './useAllArticles'
import { useAllEnglishArticles } from './useAllEnglishArticles'
import dayjsUtil from '../DayJS'
import Image from '../Image'
import { Locale, useTranslation } from '../Translation'
import { isNotUndefined, removeHtmlTags } from '../Util'

export const useAllArticles = () => {
  const { locale } = useTranslation()
  const japanesseArticles = useJapaneseAllArticles()
  const englishArticles = useAllEnglishArticles()

  return locale === 'en' ? englishArticles : japanesseArticles
}

export const useArticle = () => {
  const articles = useAllArticles()

  const articleMap = useMemo(() => {
    const map: { [id: string]: Article } = {}
    articles.forEach(article => (map[article.blogId] = article))
    return map
  }, [articles])
  const getArticles = (ids: string[]) =>
    ids.map(id => articleMap[id]).filter(isNotUndefined)

  return { articleMap, getArticles }
}

export type Article = {
  blogId: string
  path: string
  title: string
  titleWithBr: string
  thumbnail: Image
  publishDate: string
  englishPublishDate: string | null
  rawPublishDate: string
  publishDateRfc822: string
  reviseDateRfc822: string
  parentPage: ParentPage | null
  promotion: string
  tags: string[]
  data: {
    category: string
    headerImage: Image
    redirectOriginPath: string | null
    isSmallHeaderImage: boolean
    requester: string | null
  }
  contents: ArticleContent[][]
  totalPage: number
  enableVoice: boolean
  isArtisan: boolean
}
type ParentPage = {
  id: string
  title: string
  image: Image
  projectName: string | null
}
export type ArticleContent =
  | TextAreaContent
  | LinkCardContent
  | ArticleLinkCardContent
  | IframeContent
  | ImageWithTextContent
  | CenterImageContent
  | TalkContent
  | QuotationContent
  | ProfileContent
  | PagerContent
export type TextAreaContent = {
  fieldId: 'textarea'
  contents: string
}
export type LinkCardContent = {
  fieldId: 'card'
  url: string
  title: string
  image: Image | null | undefined
  contents: string | undefined
}
export type ArticleLinkCardContent = {
  fieldId: 'link'
  list: ArticleLinkCardList | null
}
type ArticleLinkCardList = {
  id: string
  title: string
  category: string
  thumbnail: Image
  parentPage: null | { id: string }
}
export type IframeContent = {
  fieldId: 'iframe'
  type: IframeType[]
  iframe: string
  contents: string | undefined | null
}
type IframeType = 'youtube' | 'instagram' | 'vimeo' | 'other'
export type ImageWithTextContent = {
  fieldId: 'leftImage' | 'rightImage'
  image: Image
  contents: string
  caption: string | undefined | null
}
export type CenterImageContent = {
  fieldId: 'centerImage'
  image: Image
  width: string[]
  url: string | undefined | null
  contents: string | undefined | null
}
export type TalkContent = {
  fieldId: 'talk'
  name: string
  contents: string
  color: string[]
}
export type QuotationContent = {
  fieldId: 'pre'
  contents: string
}
export type ProfileContent = {
  fieldId: 'profile'
  contents: string
  name: string
  image: Image | null | undefined
}
export type PagerContent = {
  fieldId: 'pager'
  nextPage: number
}

type RawData = {
  category: string
  headerImage: Image
  redirectOriginPath: string | undefined
  headerImageSize: boolean
  ogpImage: Image | undefined
  requester: string | undefined
}
type RawParentPage = {
  id: string
  title: string
  image: Image
  read: string | undefined
}

export type RawArticle = {
  id: string
  title: string
  publishDate: string
  revisedAt: string
  parentPage: RawParentPage | undefined
  promotion: string
  tagsString: string | undefined
  data: RawData
  contents: RawArticleContent[]
  enableVoice: boolean
}

type Page = {
  contents: ArticleContent[]
  textLength: number
}

type TextContent = TextAreaContent | TalkContent | ImageWithTextContent
const isTextContent = (content: ArticleContent): content is TextContent =>
  ['textarea', 'talk', 'leftImage', 'rightImage'].includes(content.fieldId)

const MIN_TEXT_LENGTH = 500

type RawArticleContent = {
  fieldId: string
  [key: string]: unknown
}
const convertArticleContents = (
  rawContents: RawArticleContent[],
  locale: Locale,
) => {
  const contents: ArticleContent[][] = []

  const initialPage = (): Page => ({
    contents: [],
    textLength: 0,
  })
  let page: Page = initialPage()
  const textLengthRate = locale === 'en' ? 2 : 1

  const pushContent = (content: ArticleContent) => {
    if (isTextContent(content)) {
      page.textLength += removeHtmlTags(content.contents).length
    }

    if (locale === 'en' && 'image' in content) {
      // 英語記事の場合、画像のURLのみが直接入っているので型を強制的に変換
      const url = content.image + ''
      content.image = { url } as Image
    }

    preProcessContent(content)
    page.contents.push(content)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rawContents.forEach((content: any) => {
    if (content.fieldId === 'pager') {
      return
    }

    if (isTextContent(content)) {
      content.contents = formatContentsString(content.contents)
      const separetedContents = ['textarea', 'talk'].includes(content.fieldId)
        ? content.contents.split('<br><br>').map(
            (text, index) =>
              ({
                ...content,
                // 2つ目の段落以降はtalkの名前などは表示する必要がない
                fieldId: index === 0 ? content.fieldId : 'textarea',
                contents: text,
              }) as TextContent,
          )
        : [content]

      separetedContents.forEach(separetedContent => {
        pushContent(separetedContent)

        if (page.textLength > MIN_TEXT_LENGTH * textLengthRate) {
          contents.push(page.contents)
          page = initialPage()
        }
      })
      return
    }

    if (content.fieldId === 'link') {
      const { list } = content

      if (list === null) {
        // 下書き記事を保存している場合、参照先がnullで帰ってくるのでコンポーネントで表示を切り替える
        pushContent(content)
        return
      }

      const data = locale === 'en' ? list.japaneseBlog.data : list.data

      pushContent({
        fieldId: 'link',
        list: {
          id: list.id,
          title: removeHtmlTags(list.title),
          category: data.category,
          thumbnail: data.ogpImage ?? data.headerImage,
          parentPage: content.list.parentPage ?? null,
        },
      })
      return
    }

    pushContent(content)
  })

  if (page.contents.length > 0) {
    contents.push(page.contents)
  }

  return contents
}

export const convertToArticle = (rawArticle: RawArticle): Article => {
  const { id, data } = rawArticle
  const { category, parentPage, path, tags } = formatCommonData(
    id,
    data,
    rawArticle,
    'ja',
  )
  const contents = convertArticleContents(rawArticle.contents, 'ja')

  return {
    blogId: id,
    path,
    title: removeHtmlTags(rawArticle.title),
    titleWithBr: rawArticle.title,
    thumbnail: data.ogpImage ?? data.headerImage,
    rawPublishDate: rawArticle.publishDate,
    publishDate: convertDate(rawArticle.publishDate),
    englishPublishDate: null, // TODO 本当に必要か確認する
    publishDateRfc822: convertDateToRfc822(rawArticle.publishDate),
    reviseDateRfc822: convertDateToRfc822(rawArticle.revisedAt),
    parentPage,
    promotion: rawArticle.promotion,
    tags,
    data: {
      category,
      headerImage: data.headerImage,
      redirectOriginPath: data.redirectOriginPath ?? null,
      isSmallHeaderImage: data.headerImageSize,
      requester: data.requester ?? null,
    },
    contents,
    totalPage: contents.length,
    enableVoice: rawArticle.enableVoice,
    isArtisan: tags.includes('Artisan'),
  }
}

export type RawArticleInEnglish = {
  title: string
  publishedAt: string
  revisedAt: string
  parentPage: RawParentPage | undefined
  promotion: string
  tagsString: string | undefined
  japaneseBlog: {
    id: string
    publishDate: string
    data: RawData
    enableVoice: boolean
  }
  contents: RawArticleContent[]
}
export const convertToArticleInEnglish = (
  rawArticle: RawArticleInEnglish,
): Article => {
  const blogId = rawArticle.japaneseBlog.id
  const data = rawArticle.japaneseBlog.data
  const { category, parentPage, path, tags } = formatCommonData(
    blogId,
    data,
    rawArticle,
    'en',
  )
  const contents = convertArticleContents(rawArticle.contents, 'en')

  return {
    blogId,
    path,
    title: removeHtmlTags(rawArticle.title, { brString: ' ' }),
    titleWithBr: rawArticle.title,
    thumbnail: data.ogpImage ?? data.headerImage,
    rawPublishDate: rawArticle.japaneseBlog.publishDate,
    englishPublishDate: convertDate(rawArticle.publishedAt),
    publishDate: convertDate(rawArticle.japaneseBlog.publishDate),
    publishDateRfc822: convertDateToRfc822(rawArticle.japaneseBlog.publishDate),
    reviseDateRfc822: convertDateToRfc822(rawArticle.revisedAt),
    parentPage,
    promotion: rawArticle.promotion,
    tags,
    data: {
      category,
      headerImage: data.headerImage,
      redirectOriginPath: data.redirectOriginPath ?? null,
      isSmallHeaderImage: data.headerImageSize,
      requester: null,
    },
    contents,
    totalPage: contents.length,
    enableVoice: rawArticle.japaneseBlog.enableVoice,
    isArtisan: tags.includes('Artisan'),
  }
}

const convertDate = (dateString: string): string => {
  return dayjsUtil.utc(dateString).tz('Asia/Tokyo').format('YYYY.MM.DD')
}

const convertDateToRfc822 = (dateString: string): string => {
  return dayjsUtil
    .utc(dateString)
    .tz('Asia/Tokyo')
    .format('ddd, DD MMM YYYY HH:mm:ss ZZ')
}

const formatCommonData = (
  blogId: string,
  data: RawData,
  rawArticle: RawArticle | RawArticleInEnglish,
  locale: Locale,
) => {
  // microCMS category is array, so change it to string.
  const category = data.category[0]
  // nullとundefinedが混ざるので、GraphQLに合わせてnullに統一
  const parentPage = rawArticle.parentPage
    ? {
        ...rawArticle.parentPage,
        projectName: rawArticle.parentPage.read ?? null,
      }
    : null
  const path = parentPage
    ? `/${category}/${parentPage.id}/${blogId}`
    : `/${category}/${blogId}`

  const tags =
    rawArticle.tagsString === undefined || rawArticle.tagsString === null
      ? []
      : rawArticle.tagsString.split(',').map(tag => tag.trim())

  return { category, parentPage, path, tags }
}

const formatContentsString = (contents: string) =>
  contents
    .replaceAll('<br>&nbsp;<br>', '<br><br>') // 改行の間にスペースが入っている場合がある
    .replaceAll(/<p[^>]*>/g, '') // pタグ内を分割すると壊れるので消す
    .replaceAll('</p>', '<br><br>') // pの終了タグは改行に置換
    .replace(/<br><br>$/, '') // 最後にも改行が入ってしまうので削除

const replaceToRubyTags = (text: string): string => {
  // {漢字|かんじ}のようなMarkdown記法を抽出できるような正規表現
  const regex = /{([^{|]+)\|([^|}]+)}/g
  const replaceTag = '<ruby>$1<rt>$2</rt></ruby>'
  return text.replaceAll(regex, replaceTag)
}

const replaceToEmphasis = (text: string): string => {
  // 《《傍点》》のようなMarkdown記法を抽出できるような正規表現
  const regex = /《《([^《》]+)》》/g
  const replaceTag = "<span style='text-emphasis:filled'>$1</span>"
  return text.replaceAll(regex, replaceTag)
}

const preProcessContent = (content: ArticleContent) => {
  if ('contents' in content) {
    if (typeof content.contents === 'string') {
      content.contents = replaceToRubyTags(content.contents)
      content.contents = replaceToEmphasis(content.contents)
    }
  }
  if ('name' in content) {
    // nameを持たないContentの型の場合nullが返されるのでその確認をする必要がある
    if (typeof content.name === 'string') {
      content.name = replaceToRubyTags(content.name)
      content.name = replaceToEmphasis(content.name)
    }
  }
  if ('caption' in content) {
    if (typeof content.caption === 'string') {
      content.caption = replaceToRubyTags(content.caption)
      content.caption = replaceToEmphasis(content.caption)
    }
  }
}

// 入力していない場合、microCMSの値は複数のパターンを持つ
export const isValidString = (value: unknown): value is string =>
  value !== null && value !== undefined && value !== ''

export const isPreview = () =>
  typeof window !== 'undefined' && window.location.pathname.includes('/preview')

export const createDescription = (article: Article) => {
  let description = ''

  const firstTextArea = article.contents
    .flat()
    .find(content => content.fieldId === 'textarea') as
    | TextAreaContent
    | undefined
  if (firstTextArea !== undefined) {
    description = removeHtmlTags(firstTextArea.contents).slice(0, 100)
  }

  return description
}

export const countTextLength = (article: Article) =>
  article.contents.flat().reduce((length, content) => {
    switch (content.fieldId) {
      case 'textarea':
      case 'talk':
      case 'leftImage':
      case 'rightImage':
        return length + content.contents.length
    }

    return length
  }, 0)
