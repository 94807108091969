import { getLocalData, setLocalData } from './LocalData'
import { Locale } from './Translation'

export type VoiceContext = 'latest' | 'ranking'
export type Voice = {
  articleIds: string[]
  playIndex: number
  isPlaying: boolean
  context?: VoiceContext
}

export type Speed = 'normal' | 'fast' | 'x-fast'

const AUDIO_URL = process.env.GATSBY_AUDIO_URL ?? ''
export const buildVoiceUrl = (
  articleId: string,
  locale: Locale,
  speed: Speed,
) => `${AUDIO_URL}/${articleId}-${locale === 'ja' ? 'ja' : 'en'}-${speed}.mp3`

const LOCAL_DATA_KEY = '20250213_audio'
export const checkMaxAudioCount = () => {
  const data = getLocalData(LOCAL_DATA_KEY)
  const audioCount = data === undefined ? 1 : parseInt(data) + 1
  setLocalData(LOCAL_DATA_KEY, `${audioCount}`)
  return audioCount > 3
}
