const ArrowLeft = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      d="M15.2852 19.0027L8.28516 12.0027L15.2852 5.00269"
      stroke="#21272A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ArrowLeft
